








































































































































































































import Vue from 'vue';
import AppMainSchedule from '@/components/AppMainSchedule.vue';
import ScheduleDay from '@/components/ScheduleDay.vue';
import AppForm from '@/components/AppForm.vue';
import FieldTime from '@/components/FieldTime.vue';
import SCHEDULE_DAY from '@/graphql/queries/ScheduleDay.graphql';
import BASE_ROUTES from '@/graphql/queries/BaseRoutes.graphql';
import DELIVERY_LOCATIONS from '@/graphql/queries/DeliveryLocations.graphql';
import USERS from '@/graphql/queries/Users.graphql';
import TRUCKS from '@/graphql/queries/Trucks.graphql';
import TRAILERS from '@/graphql/queries/TrailerNames.graphql';
import ROUTE_CREATE from '@/graphql/mutations/ScheduledRouteCreate.graphql';
import DELIVERY_CREATE from '@/graphql/mutations/DeliveryCreate.graphql';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { SCHEDULE_TYPES, USER_ROLES } from '@/utils/constants';
import { GraphQLError } from 'graphql';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import {
  ScheduleDayQuery,
  User,
  BaseRoute,
  DeliveryLocation,
  Truck,
  Trailer,
  ScheduledRouteCreateMutationVariables,
  DeliveryCreateMutationVariables,
  ScheduleDayQueryVariables,
} from '@/types/schema';

export default Vue.extend({
  name: 'AdminSchedulesAdd',
  components: {
    AppMainSchedule,
    ScheduleDay,
    AppForm,
    FieldTime,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        type: '',
        driver: '',
        trailer: '',
        truck: '',
        // Route
        route: '',
        relatedDelivery: '',
        // Delivery
        location: '',
        relatedRoute: '',
        appointmentTime: '',
        purchaseOrder: '',
      },
      relatedRoutes: [] as ScheduleDayQuery['relatedRoutes'],
      relatedDeliveries: [] as ScheduleDayQuery['relatedDeliveries'],
      users: [] as User[],
      routes: [] as BaseRoute[],
      deliveries: [] as DeliveryLocation[],
      trucks: [] as Truck[],
      trailers: [] as Trailer[],
    };
  },
  computed: {
    itemTypes(): string[] {
      return Object.values(SCHEDULE_TYPES);
    },
    itemType(): string {
      return this.form.type;
    },
    isRouteSelected(): boolean {
      return this.itemType === SCHEDULE_TYPES.ROUTE;
    },
    isDeliverySelected(): boolean {
      return this.itemType === SCHEDULE_TYPES.DELIVERY;
    },
  },
  validations() {
    return {
      form: {
        type: { required },
        driver: { required },
        route: {
          required: requiredIf((form) => form.type === SCHEDULE_TYPES.ROUTE),
        },
        location: {
          required: requiredIf((form) => form.type === SCHEDULE_TYPES.DELIVERY),
        },
        appointmentTime: {
          required: requiredIf((form) => form.type === SCHEDULE_TYPES.DELIVERY),
        },
      },
    };
  },
  methods: {
    validationMessages,
    async addItem() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.isDeliverySelected) await this.addDelivery();
        if (this.isRouteSelected) await this.addRoute();
        this.$router.replace({
          name: 'admin-schedules',
          params: { date: this.$route.params.date },
        });
        this.$notify({
          text: `${this.itemType} added successfully!`,
          type: 'success',
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    addRoute() {
      return this.$apollo.mutate({
        mutation: ROUTE_CREATE,
        variables: {
          input: {
            date: this.$route.params.date,
            baseRouteId: this.form.route,
            deliveryId: this.form.relatedDelivery,
            driverId: this.form.driver,
            trailerId: this.form.trailer,
            truckId: this.form.truck,
          },
        } as ScheduledRouteCreateMutationVariables,
      });
    },
    addDelivery() {
      const { date } = this.$route.params;
      const { appointmentTime: time } = this.form;
      const appointmentTime = `${date} ${time}`;
      return this.$apollo.mutate({
        mutation: DELIVERY_CREATE,
        variables: {
          input: {
            locationId: this.form.location,
            scheduledRouteId: this.form.relatedRoute,
            driverId: this.form.driver,
            appointmentTime,
            purchaseOrder: this.form.purchaseOrder,
            trailerId: this.form.trailer,
            truckId: this.form.truck,
          },
        } as DeliveryCreateMutationVariables,
      });
    },
    formatDayOfWeek(dateStr: string): string {
      return format(parseISO(dateStr), 'E, LLL d');
    },
    relatedObjectVariables() {
      return {
        deliveryRange: {
          start: this.$route.params.date,
          end: format(
            addDays(parseISO(this.$route.params.date), 3),
            'yyyy-MM-dd'
          ),
        },
        routeRange: {
          start: format(
            addDays(parseISO(this.$route.params.date), -3),
            'yyyy-MM-dd'
          ),
          end: this.$route.params.date,
        },
      };
    },
  },
  apollo: {
    relatedRoutes: {
      query: SCHEDULE_DAY,
      variables(): ScheduleDayQueryVariables {
        return this.relatedObjectVariables();
      },
      fetchPolicy: 'cache-and-network',
    },
    relatedDeliveries: {
      query: SCHEDULE_DAY,
      variables(): ScheduleDayQueryVariables {
        return this.relatedObjectVariables();
      },
      fetchPolicy: 'cache-and-network',
    },
    users: {
      query: USERS,
      variables: {
        role: USER_ROLES.DRIVER,
      },
      skip(): boolean {
        return !this.itemType;
      },
    },
    routes: {
      query: BASE_ROUTES,
      skip(): boolean {
        return !this.isRouteSelected;
      },
    },
    deliveries: {
      query: DELIVERY_LOCATIONS,
      skip(): boolean {
        return !this.isDeliverySelected;
      },
    },
    trucks: {
      query: TRUCKS,
    },
    trailers: {
      query: TRAILERS,
    },
  },
});
